interface FileInputProps {
  file?: File
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  resetFile(): void
}

const Fileinput = ({ file, onChange, resetFile }: FileInputProps) => {
  return (
    <div className="flex w-full justify-start text-white">
      <div className="max-w-[240px]">
        {!file ? (
          <input
            className="max-w-[200px] cursor-pointer"
            type="file"
            onChange={onChange}
            accept="application/json"
          />
        ) : (
          <p className="">
            {file.name}
            <span
              onClick={resetFile}
              className="rioglyph rioglyph-remove mx-5 cursor-pointer text-16 text-danger"
            />
          </p>
        )}
      </div>
    </div>
  )
}

export default Fileinput
