import { useEffect, useState } from 'react'
import Button from 'components/actions/Button'
import ErrorMessage from './ErrorMessage'
import { setHeaders } from './utils'
import { fetchAuthSession } from 'aws-amplify/auth'
import Fileinput from './FileInput'
import FilePreview from './FilePreview'

interface UploadScenarioFormProps {
  domain: string | undefined
}
/**
 * Fot future implementations:
 * - how to validate vin - and disable Upload Scenario button
 */
const UploadScenarioForm = ({ domain }: UploadScenarioFormProps) => {
  const [inputVIN, setInputVIN] = useState<string | undefined>(undefined)
  const [inputCustomerId, setInputCustomerId] = useState<string | undefined>(undefined)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileContent, setFileContent] = useState<string | undefined>()
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false)
  // request flow
  const [isRequestSending, setIsRequestSending] = useState<boolean>(false)
  const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false)

  const closeErrorMessage = () => {
    setIsRequestFailed(false)
  }

  const handlePreviewBox = () => {
    if (fileContent && !isPreviewOpen) {
      setIsPreviewOpen(true)
    } else if (fileContent && isPreviewOpen) {
      setIsPreviewOpen(false)
    }
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) {
      return
    }
    const fileInput = files[0]
    setFile(fileInput)
  }

  const resetFile = () => {
    setFile(undefined)
    setFileContent(undefined)
    setIsPreviewOpen(false)
  }

  useEffect(() => {
    const readFile = () => {
      const fileReader = new FileReader()
      if (file) {
        fileReader.readAsText(file, 'UTF-8')
        fileReader.onload = (readerEvent) => {
          if (readerEvent?.target?.result) {
            setFileContent(readerEvent.target.result.toString())
          }
        }
      }
    }

    readFile()
  }, [file])

  const uploadSimulation = async () => {
    const url = `${domain}/simulator-adapter-service/api/v1/simulation/upload/${inputVIN}?customerId=${inputCustomerId}`
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    try {
      if (file && inputCustomerId) {
        const formData = new FormData()
        formData.append('file', file)

        await fetch(url, {
          method: 'POST',
          headers: setHeaders(accessToken),
          body: formData,
        })

        setIsRequestFailed(false)
        setIsRequestSending(true)

        console.info('[Simulation] File to be uploaded', file)
      }
    } catch (error) {
      setIsRequestFailed(true)
      console.error(error)
    } finally {
      setIsRequestSending(false)
    }
  }

  return (
    <div className="flex w-full flex-col items-center rounded-xl bg-gray-darkest p-20 shadow-sm">
      {isRequestFailed && (
        <ErrorMessage message="Error while uploading scenario." onClose={closeErrorMessage} />
      )}
      <div className="grid w-full grid-rows-4 items-center gap-15">
        <input
          onChange={(e) => setInputVIN(e.currentTarget.value)}
          type="text"
          className="form-control"
          id="inputVIN"
          placeholder="Vehicle VIN"
        />
        <input
          onChange={(e) => setInputCustomerId(e.currentTarget.value)}
          type="text"
          className="form-control"
          id="inputCustomerId"
          placeholder="Customer Id"
        />
        <div className="mr-20">
          <Fileinput file={file} onChange={handleFile} resetFile={resetFile} />
        </div>
        <Button
          data-testid="upload-simulation-scenario-button"
          disabled={!inputVIN || !inputCustomerId || !file || isRequestSending}
          className="btn btn-primary"
          type="submit"
          onClick={uploadSimulation}
        >
          <div className="flex items-center p-10">
            {/*
              Actual implementation: the simulation is triggered as soon the scenario is uploaded.
              Change button label and button icon.

              <span className="rioglyph rioglyph-upload mr-5 text-16" />
              <p>Upload scenarion</p>
            */}
            <span className="rioglyph rioglyph-play mr-5 text-16" />
            <p>Start simulation</p>
          </div>
        </Button>
      </div>
      <i
        onClick={handlePreviewBox}
        className={`mt-5 w-full border-b border-gray-dark text-center ${fileContent && file ? 'cursor-pointer text-white hover:bg-gray-darker' : 'cursor-default text-gray-dark'}`}
      >
        {isPreviewOpen ? 'Close' : 'Open'} file preview
      </i>
      {fileContent && isPreviewOpen && <FilePreview file={file} content={fileContent} />}
    </div>
  )
}

export default UploadScenarioForm
