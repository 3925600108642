import { useRef, useState } from 'react'
import Map from '@rio-cloud/rio-uikit/Map'
import { getMapCredentials } from 'configuration'
import EventUtils from '@rio-cloud/rio-uikit/EventUtils'
import { MapApi, MapContextMenuEvent, MapEvent, Position } from '@rio-cloud/rio-uikit/mapTypes'
import SingleMapMarker from '@rio-cloud/rio-uikit/SingleMapMarker'
import Route from '@rio-cloud/rio-uikit/Route'
import ContextMenu from '@rio-cloud/rio-uikit/ContextMenu'
import ContextMenuItem from '@rio-cloud/rio-uikit/ContextMenuItem'

const mapCredentials = getMapCredentials()

interface SimulationMapProps {
  route: Position[] | undefined
  hasIntermediate?: boolean
  setStartPosition(coord: Position): void
  setDestinationPosition(coord: Position): void
  handleIntermediatePoint(coord: Position): void
}

type ContextMenuData = {
  target?: string
  event?: MapContextMenuEvent
  targetPosition?: Position
}

const SimulationMap = ({
  route,
  hasIntermediate,
  setStartPosition,
  setDestinationPosition,
  handleIntermediatePoint
}: SimulationMapProps) => {
  const centerMapPosition = { lat: 48.03, lng: 11.7 } // TODO: define default initial center position
  const [pointerCoordinates, setPointerCoordinates] = useState<Position | undefined>(undefined)
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>({})

  const mapApiRef = useRef<MapApi>()

  const handleOpenContextMenu = (contextMenuCoordinates: Position) => {
    setPointerCoordinates(contextMenuCoordinates)
  }

  const mapEventListenerMap = {
    [EventUtils.TAP]: (event: MapEvent) => {
      if (EventUtils.isRightClick(event)) {
        event.stopPropagation()
        return
      }
    },
    [EventUtils.CONTEXTMENU]: (event: MapEvent) => {
      setContextMenuData({ target: 'marker', event, targetPosition: pointerCoordinates })
    }
  }

  const setStartOption = (
    <ContextMenuItem
      key="start-point-option"
      label="Set start"
      icon="rioglyph-map-marker"
      callback={setStartPosition}
    />
  )

  const setIntermediateOption = (
    <ContextMenuItem
      key="intermediate-point-option"
      label={!hasIntermediate ? 'Set intermediate' : 'Remove intermediate'}
      icon="rioglyph-map-marker"
      callback={handleIntermediatePoint}
    />
  )

  const setDestinationOption = (
    <ContextMenuItem
      key="start-point-option"
      label="Set destination"
      icon="rioglyph-map-marker"
      callback={setDestinationPosition}
    />
  )

  return (
    <div className="size-full">
      <Map
        credentials={mapCredentials}
        center={pointerCoordinates ? undefined : centerMapPosition}
        zoom={10}
        eventListenerMap={mapEventListenerMap}
        hideMapSettings
      >
        {(api) => {
          mapApiRef.current = api

          return (
            <>
              {route && (
                <Route
                  positions={route}
                  startIcon={
                    <SingleMapMarker iconNames={['start']} markerColor="bg-map-marker-route" />
                  }
                  endIcon={
                    <SingleMapMarker iconNames={['finish']} markerColor="bg-map-marker-route" />
                  }
                  hasArrows={false}
                  style={{
                    width: 5,
                    borderWidth: 2,
                    isBorderIncludedInWidth: false,
                    color: '#328092',
                    borderColor: '#255F6C'
                  }}
                />
              )}
              <ContextMenu
                onOpen={handleOpenContextMenu}
                menuItems={[setStartOption, setIntermediateOption, setDestinationOption]}
                contextMenuEvent={contextMenuData.event}
                targetPosition={pointerCoordinates}
              />
            </>
          )
        }}
      </Map>
    </div>
  )
}

export default SimulationMap
