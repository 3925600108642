import { configureStore as configureStoreReduxToolkit } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import { rootReducer } from '../reducers'
import { hubsApi } from 'services/hubs'
import { routesApi } from 'services/routes'
import { vehiclesApi } from 'services/vehicles'
import { userApi } from 'services/user'
import { missionApi } from 'services/mission'
import { reverseGeocodeApi } from 'services/reverseGeocode'
import { commandApi } from 'services/command'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { listenerMiddleware } from './listenerMiddleware'
import { roadRestrictionsApi } from '../../services/roadRestrictions'
import { systemHealthApi } from '../../services/systemHealth'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'user', 'config', 'mrm'],
  blacklist: [
    'hubs',
    'mission',
    'reverseGeocode',
    'roadRestrictions',
    'vehicles',
    'command',
    'app.pages',
    'app.isCameraStreamMaximized',
    'app.selectedCamera'
  ]
}

const reducer = persistReducer(persistConfig, withReduxStateSync(rootReducer))

export const configureStore = (preloadedState: any = undefined) =>
  configureStoreReduxToolkit({
    preloadedState,
    reducer,
    // FIXME
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(
        hubsApi.middleware,
        vehiclesApi.middleware,
        userApi.middleware,
        routesApi.middleware,
        missionApi.middleware,
        commandApi.middleware,
        reverseGeocodeApi.middleware,
        roadRestrictionsApi.middleware,
        listenerMiddleware.middleware,
        systemHealthApi.middleware,
        createStateSyncMiddleware({
          whitelist: [
            'app/setSelectedVehicle',
            'app/clearSelectedVIN',
            'app/clearSelectedVehicle',
            'app/setIsVehicleDetailsOpen',
            'mrm/setIsMRMConfirmationDialogOpen',
            'mrm/setIsReleaseConfirmationDialogOpen',
            'mrm/setIsErrorDialogOpen',
            'mrm/setIsSendingCommand',
            'mrm/setIsAwaitingAnswer',
            'assignMission/setConfirmationDialogOpen',
            'assignMission/setIsErrorDialogOpen',
            'cancelMission/setConfirmationDialogOpen',
            'cancelMission/setIsAwaitingResponse',
            'cancelMission/setErrorDialogOpen',
            'resetMission/setConfirmationDialogOpen',
            'resetMission/setIsAwaitingResponse',
            'resetMission/setErrorDialogOpen',
            'archiveMission/setConfirmationDialogOpen',
            'archiveMission/setIsAwaitingResponse',
            'archiveMission/setErrorDialogOpen'
          ]
        })
      )
  })

export const store = configureStore()
export const persistor = persistStore(store)

/* Important: when changing or deleting existing state attributes, increment
  this number to force a full purge of any persisted state on app load.
   */
const STORE_VERSION = 7

export const purgeIfVersionMismatch = () => {
  const purgeAndReload = () => {
    persistor.purge()
    window.location.reload()
  }
  const version = Number(localStorage.getItem('STORE_VERSION'))
  if (!version) {
    localStorage.setItem('STORE_VERSION', STORE_VERSION.toString())
    purgeAndReload()
    return
  }

  if (version < STORE_VERSION) {
    localStorage.setItem('STORE_VERSION', STORE_VERSION.toString())
    console.info('[CC] Newer Store version detected. Purging persisted state.')
    purgeAndReload()
  }
}

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type RootDispatch = typeof store.dispatch

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
