import Button from 'components/actions/Button'
import { useState } from 'react'
import ErrorMessage from './ErrorMessage'
import { fetchAuthSession } from 'aws-amplify/auth'
import { setHeaders } from './utils'

interface StopSimulationFormProps {
  domain: string | undefined
}

const StopSimulationForm = ({ domain }: StopSimulationFormProps) => {
  const [inputVIN, setInputVIN] = useState<string | undefined>(undefined)
  // request flow
  const [isRequestSending, setIsRequestSending] = useState<boolean>(false)
  const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false)

  const closeErrorMessage = () => {
    setIsRequestFailed(false)
  }

  const stopSimulation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { accessToken } = (await fetchAuthSession()).tokens ?? {}
    const url = `${domain}/simulator-adapter-service/api/v1/simulation/stop/${inputVIN}`
    try {
      await fetch(url, { method: 'POST', headers: setHeaders(accessToken) })
      setIsRequestFailed(false)
      setIsRequestSending(true)
    } catch (error) {
      setIsRequestFailed(true)
      console.error(error)
    } finally {
      setIsRequestSending(false)
    }
  }

  return (
    <div className="flex w-full flex-col items-center rounded-xl bg-gray-darkest p-20 shadow-sm">
      {isRequestFailed && (
        <ErrorMessage message="Error while stopping simulation." onClose={closeErrorMessage} />
      )}
      <form
        className="form-group m-0 flex w-full flex-col items-center gap-10"
        onSubmit={stopSimulation}
      >
        <input
          onChange={(e) => setInputVIN(e.currentTarget.value)}
          type="text"
          className="form-control w-full"
          id="inputVIN"
          placeholder="Vehicle VIN"
        />
        <Button
          data-testid="stop-simulation-button"
          disabled={!inputVIN || isRequestSending}
          className="btn btn-danger w-full"
          type="submit"
        >
          <div className="flex items-center p-10">
            <span className="mr-5 size-10 bg-white" />
            <p>Stop simulation</p>
          </div>
        </Button>
      </form>
    </div>
  )
}

export default StopSimulationForm
