import { Position } from '@rio-cloud/rio-uikit/mapTypes'
import Button from 'components/actions/Button'

interface GenerateRouteFormProps {
  route: Position[] | undefined
  startPoint: Position | undefined
  intermediatePoint?: Position | undefined
  destinationPoint: Position | undefined
  setStartPoint(coord: Position): void
  setDestinationPoint(coord: Position): void
  setIntermediatePoint(coord: Position): void
  resetIntermediatePoint(): void
  hasIntermediatePoint?: boolean
  clearRoute(): void
}

const GenerateRouteForm = ({
  route,
  clearRoute,
  startPoint,
  setStartPoint,
  intermediatePoint,
  setIntermediatePoint,
  destinationPoint,
  setDestinationPoint,
  resetIntermediatePoint,
  hasIntermediatePoint
}: GenerateRouteFormProps) => {
  const generateWaypoints = (waypoints: Position[]) => {
    return waypoints.map((_) => {
      return { position: [_.lat, _.lng] }
    })
  }

  const downloadFile = () => {
    if (route) {
      const data = new Blob([JSON.stringify(generateWaypoints(route), null, '\t')], {
        type: 'text/json'
      })
      const jsonFile = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = jsonFile
      link.download = 'scenario.json'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <div className="flex items-end gap-15 rounded-xl bg-gray-darkest px-20 pb-15 pt-10 shadow-sm">
      <div className="grid w-full grid-cols-3 items-center gap-15">
        <div className="flex-col">
          <p className="mb-5 text-white">Start(lat, lng):</p>
          <div className="grid gap-5">
            <input
              onChange={(e) =>
                setStartPoint({
                  lng: startPoint?.lng ?? 0,
                  lat: Number(e.currentTarget.value)
                })
              }
              value={startPoint?.lat}
              type="text"
              className="form-control text-12"
              placeholder="Start latitude"
            />
            <input
              onChange={(e) =>
                setStartPoint({
                  lat: startPoint?.lat ?? 0,
                  lng: Number(e.currentTarget.value)
                })
              }
              value={startPoint?.lng}
              type="text"
              className="form-control text-12"
              placeholder="Start longitude"
            />
          </div>
        </div>
        {hasIntermediatePoint && !!intermediatePoint && (
          <div className="flex-col">
            <p className="mb-5 flex items-center text-white">
              Intermediate(lat, lng):{' '}
              <i
                onClick={resetIntermediatePoint}
                className="ml-10 cursor-pointer text-12 text-danger underline"
              >
                Remove
              </i>
            </p>
            <div className="grid gap-5">
              <input
                onChange={(e) =>
                  setIntermediatePoint({
                    lng: intermediatePoint?.lng ?? 0,
                    lat: Number(e.currentTarget.value)
                  })
                }
                value={intermediatePoint?.lat}
                type="text"
                className="form-control text-12"
                placeholder="Start latitude"
              />
              <input
                onChange={(e) =>
                  setIntermediatePoint({
                    lat: intermediatePoint?.lat ?? 0,
                    lng: Number(e.currentTarget.value)
                  })
                }
                value={intermediatePoint?.lng}
                type="text"
                className="form-control text-12"
                placeholder="Start longitude"
              />
            </div>
          </div>
        )}
        <div className="flex-col">
          <p className="mb-5 text-white">Destination(lat, lng):</p>
          <div className="grid gap-5">
            <input
              onChange={(e) =>
                setDestinationPoint({
                  lng: startPoint?.lng ?? 0,
                  lat: Number(e.currentTarget.value)
                })
              }
              value={destinationPoint?.lat}
              type="text"
              className="form-control text-12"
              placeholder="Destination latitude"
            />
            <input
              onChange={(e) =>
                setDestinationPoint({
                  lng: startPoint?.lng ?? 0,
                  lat: Number(e.currentTarget.value)
                })
              }
              value={destinationPoint?.lng}
              type="text"
              className="form-control text-12"
              placeholder="Destination longitude"
            />
          </div>
        </div>
      </div>
      <div className="flex h-full flex-col items-end gap-5">
        <Button
          data-testid="generate-route-button"
          disabled={!startPoint || !destinationPoint}
          className="btn btn-primary w-full"
          onClick={downloadFile}
        >
          <span className="rioglyph rioglyph-download mr-5 text-16" />
          <p>Download route</p>
        </Button>
        <Button
          data-testid="generate-route-button"
          disabled={!startPoint || !destinationPoint}
          className="btn w-full"
          onClick={clearRoute}
        >
          <span className="rioglyph rioglyph-trash mr-5 text-16" />
          <p>Clear route</p>
        </Button>
      </div>
    </div>
  )
}

export default GenerateRouteForm
