import { factory, manyOf, nullable, oneOf, primaryKey } from '@mswjs/data'
import { faker } from '@faker-js/faker'
import { fakePosition } from './utils'
import { v4 as uuid } from 'uuid'
import {
  AdsState,
  DrivingState,
  Severity,
  MissionStateValue,
  MissionEventType,
  RoadSegmentDirection,
  RoadSegmentRestrictionCause,
  VideoStreamingErrorCode,
  ExceptionRequestStatus,
  MRMStateValue,
  ConnectivityState,
  Reason
} from 'API'
import { BASE_64_IMAGES, WAYPOINTS, ROAD_RESTRICTION_WAYPOINTS, STREAM_URLS } from './constants'

faker.seed(1337)

export const VINS = [
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin()
]
export const MISSION_IDS = [
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid()
]
export const HUB_IDS = [
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid()
]

const position = {
  latitude: () => Number(fakePosition().latitude),
  longitude: () => Number(fakePosition().longitude),
  heading: () => Number(fakePosition().heading),
  altitude: () => Number(fakePosition().altitude)
}

const connectivityStatus = {
  state: () => faker.helpers.arrayElement(Object.values(ConnectivityState)),
  expectedDisconnect: () => faker.datatype.boolean(),
  latency: () => faker.number.int({ min: 0, max: 1000 }),
  reason: () => faker.helpers.arrayElement(Object.values(Reason)),
  description: () => faker.lorem.text()
}

const state = {
  updatedAt: () => faker.date.anytime().toISOString(),
  value: () => faker.helpers.arrayElement(Object.values(MissionStateValue))
}

const mrmState = {
  updatedAt: () => faker.date.anytime().toISOString(),
  mrmStateValue: () => faker.helpers.arrayElement(Object.values(MRMStateValue))
}

// Data Model
export const MockDatabase = factory({
  hub: {
    id: primaryKey(() => faker.helpers.arrayElement(HUB_IDS)),
    name: () => faker.company.name(),
    address: () => faker.location.streetAddress(),
    position,
    active: () => faker.datatype.boolean(),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString()
  },
  vehicle: {
    vin: primaryKey(faker.vehicle.vin),
    externalId: () => faker.string.uuid(),
    customerId: () => faker.string.uuid(),
    name: () => faker.person.firstName(),
    licensePlate: () => faker.vehicle.vrm(),
    position,
    adsState: () => faker.helpers.arrayElement(Object.values(AdsState)),
    drivingState: () => faker.helpers.arrayElement(Object.values(DrivingState)),
    speedInMs: () => faker.number.float(),
    weightInKg: () => faker.number.float(),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString(),
    latestVehicleUpdate: () => faker.date.recent().toISOString(),
    connectivityStatus,
    mrmState,
    deviations: manyOf('deviation'),
    owners: manyOf('owner'),
    activeMissionId: () => faker.helpers.arrayElement(MISSION_IDS),
    cameras: manyOf('camera')
  },
  deviation: {
    id: primaryKey(faker.string.uuid),
    severity: () => faker.helpers.arrayElement(Object.values(Severity)),
    category: () => faker.string.sample(),
    description: () => faker.lorem.text(),
    origin: () => faker.company.buzzNoun(),
    sentAt: () => faker.date.anytime().toISOString()
  },
  owner: {
    id: primaryKey(faker.string.uuid),
    fullName: () => `${faker.person.firstName()} ${faker.person.lastName()}`,
    position: () => faker.person.jobTitle(),
    phoneNumber: () => faker.phone.number(),
    email: () => faker.internet.email()
  },
  missionEvent: {
    id: primaryKey(faker.string.uuid),
    type: () => faker.helpers.arrayElement(Object.values(MissionEventType)),
    createdAt: () => faker.date.anytime().toISOString(),
    position
  },
  mission: {
    id: primaryKey(() => faker.helpers.arrayElement(MISSION_IDS)),
    actualArrivalTime: () => faker.date.anytime().toISOString(),
    actualStartTime: () => faker.date.anytime().toISOString(),
    createdAt: () => faker.date.anytime().toISOString(),
    description: () => faker.lorem.paragraph(),
    name: () => faker.person.firstName(),
    plannedArrivalTime: () => faker.date.anytime().toISOString(),
    progressDetails: oneOf('progressDetails'),
    route: oneOf('generatedRoute'),
    state,
    updatedAt: () => faker.date.anytime().toISOString(),
    vin: () => faker.helpers.arrayElement(VINS),
    events: manyOf('missionEvent')
  },
  progressDetails: {
    id: primaryKey(faker.string.uuid),
    eta: () => faker.date.anytime().toISOString(),
    remainingDistanceInMeters: () => faker.number.int(), // TODO: define a better range
    traveledDistanceInMeters: () => faker.number.int(),
    updatedAt: () => faker.date.anytime().toISOString()
  },
  route: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    averageDuration: () => faker.number.int({ min: 60, max: 4 * 3600 }),
    landingHub: oneOf('hub'),
    launchHub: oneOf('hub'),
    distance: () => faker.number.int({ min: 1200, max: 500_000 }),
    updatedAt: () => faker.date.anytime().toISOString(),
    // TODO: find a cleaner way to define this.
    // Actually we just need the fixed values from our waypoints mock but still need some type here
    waypoints: () => faker.helpers.arrayElements([]) as any
  },
  generatedRoute: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    durationInSeconds: () => faker.number.int({ min: 60, max: 4 * 3600 }),
    externalRouteId: () => faker.string.uuid(),
    landingHub: oneOf('hub'),
    landingHubId: () => faker.helpers.arrayElement(HUB_IDS),
    launchHub: oneOf('hub'),
    launchHubId: () => faker.helpers.arrayElement(HUB_IDS),
    predefinedRouteId: () => faker.string.uuid(),
    totalDistanceInMeters: () => faker.number.int({ min: 1200, max: 500_000 }),
    updatedAt: () => faker.date.anytime().toISOString(),
    // TODO: find a cleaner way to define this.
    // Actually we just need the fixed values from our waypoints mock but still need some type here
    waypoints: () => faker.helpers.arrayElements([]) as any
  },
  hereAddress: {
    label: primaryKey(() => faker.location.streetAddress()),
    countryCode: () => faker.location.countryCode(),
    countryName: () => faker.location.country(),
    state: () => faker.location.state(),
    county: () => faker.location.county(),
    city: () => faker.location.city(),
    district: () => faker.location.zipCode(),
    street: () => faker.location.street(),
    postalCode: () => faker.location.zipCode(),
    houseNumber: () => faker.location.buildingNumber()
  },
  hereAddressItem: {
    id: primaryKey(() => faker.string.uuid()),
    title: () => faker.location.street(),
    houseNumberType: () => faker.location.buildingNumber(),
    distance: () => faker.number.float(),
    address: oneOf('hereAddress'),
    position: {
      lat: () => faker.location.latitude(),
      lng: () => faker.location.longitude()
    }
  },
  cameraImage: {
    value: () => faker.helpers.arrayElement(BASE_64_IMAGES),
    timestamp: primaryKey(() => faker.date.anytime().toISOString())
  },
  videoStream: {
    vin: () => faker.helpers.arrayElement(VINS),
    cameraID: primaryKey(() => faker.string.uuid()),
    streamingURL: () => faker.helpers.arrayElement(STREAM_URLS)
  },
  roadSegment: {
    id: primaryKey(() => faker.string.uuid()),
    waypoints: () => faker.helpers.arrayElements([]) as any,
    from: () => faker.number.int({ min: 1, max: 50_000 }),
    to: () => faker.number.int({ min: 1, max: 50_000 }),
    direction: () => faker.helpers.arrayElement(Object.values(RoadSegmentDirection))
  },
  roadSegmentStatus: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString(),
    reportedFromSourceAt: () => faker.date.anytime().toISOString(),
    source: () => faker.helpers.arrayElement(['Autobahn GmbH', 'Manualbahn GmbH']),
    segment: oneOf('roadSegment'),
    restrictionStart: () => faker.date.anytime().toISOString(),
    restrictionEnd: () => faker.date.anytime().toISOString(),
    restrictionCauses: () => faker.helpers.arrayElements(Object.values(RoadSegmentRestrictionCause))
  },
  videoStreamingError: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString(),
    vin: () => faker.helpers.arrayElement(VINS),
    cameraID: nullable(String),
    errorMessage: () => faker.lorem.sentence(),
    errorCode: () => faker.helpers.arrayElement(Object.values(VideoStreamingErrorCode))
  },
  camera: {
    name: primaryKey(() => faker.word.noun()),
    id: nullable<string>(() => null)
  },
  exceptionSegment: {
    id: primaryKey(() => faker.string.uuid()),
    from: () => faker.number.int({ min: 1, max: 50_000 }),
    to: () => faker.number.int({ min: 1, max: 50_000 }),
    direction: () => faker.helpers.arrayElement(Object.values(RoadSegmentDirection))
  },
  exceptionResponse: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    exceptionType: () => faker.helpers.arrayElement(Object.values(RoadSegmentRestrictionCause)),
    exceptionStart: () => faker.date.anytime().toISOString(),
    exceptionEnd: () => faker.date.anytime().toISOString(),
    exceptionSegment: oneOf('exceptionSegment'),
    status: () => faker.helpers.arrayElement(Object.values(ExceptionRequestStatus))
  }
})

// Hubs
MockDatabase.hub.create({
  id: HUB_IDS[0],
  name: 'Hub I',
  active: true,
  address: faker.location.streetAddress(),
  position: {
    latitude: 48.3418733,
    longitude: 11.6067098
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[1],
  name: 'Hub II',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.3866331,
    longitude: 11.5974676
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[2],
  name: 'Hub Riem Arcaden',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.13243255052443,
    longitude: 11.691210499999999
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[3],
  name: 'Hub Augsburg Airport',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.42788804304301,
    longitude: 10.952264305199673
  }
})

/**
 * Bumblebee
 * - connectivityStatus: ONLINE
 * - In MRC
 */
MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Bumblebee',
  vin: VINS[0],
  drivingState: DrivingState.DRIVING,
  position: {
    latitude: 48.3408733,
    longitude: 11.6067098,
    heading: 0.0
  },
  mrmState: {
    mrmStateValue: MRMStateValue.MRC,
    updatedAt: new Date().toISOString()
  },
  activeMissionId: MISSION_IDS[0],
  connectivityStatus: {
    state: ConnectivityState.ONLINE,
    latency: 500
  },
  owners: [MockDatabase.owner.create(), MockDatabase.owner.create()],
  deviations: [
    MockDatabase.deviation.create({
      description: 'H2H_MRC',
      severity: Severity.ERROR
    })
  ],
  cameras: [
    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() })
  ]
})

/**
 * Optimus Prime
 * - connectivityStatus: OFFLINE, expectedDisconnect: false
 */
MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Optimus Prime',
  vin: VINS[1],
  drivingState: DrivingState.UNKNOWN,
  activeMissionId: MISSION_IDS[1],
  position: {
    latitude: 48,
    longitude: 11,
    heading: 0,
    altitude: 0
  },
  connectivityStatus: {
    state: ConnectivityState.ONLINE
  },
  speedInMs: 0,
  deviations: [
    MockDatabase.deviation.create({
      origin: 'CONNECTIVITY',
      description: 'Connection failed',
      severity: Severity.ERROR
    })
  ],
  owners: [MockDatabase.owner.create()],
  cameras: [MockDatabase.camera.create({ id: faker.string.uuid() })]
})

/**
 * Megatron
 * - connectivityStatus: ONLINE
 * - In MRC
 */
MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Megatron',
  vin: VINS[2],
  drivingState: DrivingState.PARKED,
  activeMissionId: MISSION_IDS[2],
  position: {
    latitude: 48.1966921,
    longitude: 11.6994121,
    heading: 0.0
  },
  mrmState: {
    mrmStateValue: MRMStateValue.MRC,
    updatedAt: new Date().toISOString()
  },
  deviations: [
    MockDatabase.deviation.create({
      description: 'H2H_MRC',
      severity: Severity.ERROR
    })
  ],
  owners: [MockDatabase.owner.create(), MockDatabase.owner.create()],
  cameras: [MockDatabase.camera.create({ id: undefined, name: 'DEFAULT' })]
})

/**
 * Starscream
 * - connectivityStatus: OFFLINE, expectedDisconnect: true
 */
MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Starscream',
  vin: VINS[5],
  drivingState: DrivingState.PARKED,
  activeMissionId: MISSION_IDS[5],
  position: {
    latitude: 48.423773765612154,
    longitude: 10.9199119181742,
    heading: 0.0
  },
  mrmState: {
    mrmStateValue: MRMStateValue.MRC,
    updatedAt: new Date().toISOString()
  },
  connectivityStatus: {
    state: ConnectivityState.OFFLINE,
    expectedDisconnect: true
  },
  deviations: [
    MockDatabase.deviation.create({
      origin: 'CONNECTIVITY',
      description: 'Connection loss',
      severity: Severity.WARNING
    })
  ],
  owners: [],
  cameras: [
    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() })
  ]
})

/**
 * Blackout
 * - connectivityStatus: ONLINE
 */
MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Blackout',
  vin: VINS[6],
  activeMissionId: MISSION_IDS[6],
  position: {
    latitude: 48.41959268770702,
    longitude: 10.919932745248357,
    heading: 0.0
  },
  connectivityStatus: {
    state: ConnectivityState.ONLINE
  },
  deviations: [],
  owners: [],
  cameras: [
    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() }),

    MockDatabase.camera.create({ id: faker.string.uuid() })
  ]
})

// no mission assigned
MockDatabase.vehicle.create({
  vin: VINS[3],
  name: 'Truck w/o mission',
  drivingState: DrivingState.DRIVING,
  activeMissionId: undefined,
  deviations: [
    MockDatabase.deviation.create({
      severity: Severity.WARNING
    })
  ],
  cameras: [
    MockDatabase.camera.create({ id: faker.string.uuid() }),
    MockDatabase.camera.create({ id: faker.string.uuid() }),
    MockDatabase.camera.create({ id: faker.string.uuid() })
  ]
})

/**
 * Routes
 */

const ALLHUBS = MockDatabase.hub.getAll()

MockDatabase.route.create({
  launchHub: ALLHUBS[0],
  landingHub: ALLHUBS[1],
  averageDuration: 6 * 3600, // 6 hours
  waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
  distance: 500_000
})

MockDatabase.route.create({
  launchHub: ALLHUBS[2],
  landingHub: ALLHUBS[3],
  averageDuration: 50 * 60,
  waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
  distance: 82_000
})

const ALLROUTES = MockDatabase.route.getAll()

MockDatabase.generatedRoute.create({
  id: ALLROUTES[0]?.id,
  launchHub: ALLHUBS[0],
  landingHub: ALLHUBS[1],
  launchHubId: ALLHUBS[0]?.id,
  landingHubId: ALLHUBS[1]?.id,
  durationInSeconds: 6 * 3600, // 6 hours
  waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
  totalDistanceInMeters: 500_000
})

MockDatabase.generatedRoute.create({
  id: ALLROUTES[1]?.id,
  launchHub: ALLHUBS[2],
  landingHub: ALLHUBS[3],
  durationInSeconds: 50 * 60,
  launchHubId: ALLHUBS[2]?.id,
  landingHubId: ALLHUBS[3]?.id,
  waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
  totalDistanceInMeters: 82_000
})

const ALLGENERATEDROUTES = MockDatabase.generatedRoute.getAll()

// assigned
MockDatabase.mission.create({
  id: MISSION_IDS[3],
  vin: VINS[4],
  name: `${ALLROUTES[0]?.launchHub?.name} <> ${ALLROUTES[0]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.ASSIGNED
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: ALLGENERATEDROUTES[0],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:07:00Z').toISOString(),
    traveledDistanceInMeters: 480_000,
    remainingDistanceInMeters: 20_000
  })
})

// in proggress - on time
MockDatabase.mission.create({
  id: MISSION_IDS[0],
  vin: VINS[0],
  name: `${ALLROUTES[0]?.launchHub?.name} <> ${ALLROUTES[0]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.ASSIGNED
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: ALLGENERATEDROUTES[0],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:30:00Z').toISOString(),
    traveledDistanceInMeters: 10_000,
    remainingDistanceInMeters: 490_000
  }),
  events: [
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T12:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_STARTED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T13:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T14:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_COMPLETED
    })
  ]
})

// in progress - early
MockDatabase.mission.create({
  id: MISSION_IDS[1],
  vin: VINS[1],
  name: `${ALLGENERATEDROUTES[0]?.launchHub?.name} <> ${ALLGENERATEDROUTES[0]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.ACTIVE
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: ALLGENERATEDROUTES[0],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:07:00Z').toISOString(),
    traveledDistanceInMeters: 480_000,
    remainingDistanceInMeters: 20_000
  }),
  events: [
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T12:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_STARTED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T13:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T14:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_COMPLETED
    })
  ]
})

// in progress - delayed
MockDatabase.mission.create({
  id: MISSION_IDS[2],
  vin: VINS[2],
  name: `${ALLGENERATEDROUTES[0]?.launchHub?.name} <> ${ALLGENERATEDROUTES[0]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.CANCELED
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: ALLGENERATEDROUTES[0],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:55:00Z').toISOString(),
    traveledDistanceInMeters: 200_000,
    remainingDistanceInMeters: 300_000
  })
})

// complete - on time
MockDatabase.mission.create({
  id: MISSION_IDS[5],
  vin: VINS[5],
  name: `${ALLGENERATEDROUTES[1]?.launchHub?.name} <> ${ALLGENERATEDROUTES[1]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T13:48:00Z').toISOString(),
  route: ALLGENERATEDROUTES[1],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:50:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// complete - delayed
MockDatabase.mission.create({
  id: MISSION_IDS[6],
  vin: VINS[6],
  name: `${ALLGENERATEDROUTES[1]?.launchHub?.name} <> ${ALLGENERATEDROUTES[1]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T14:15:00Z').toISOString(),
  route: ALLGENERATEDROUTES[1],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T14:15:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// complete - early
MockDatabase.mission.create({
  id: MISSION_IDS[7],
  vin: VINS[7],
  name: `${ALLGENERATEDROUTES[1]?.launchHub?.name} <> ${ALLGENERATEDROUTES[1]?.landingHub?.name}`,
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T13:40:00Z').toISOString(),
  route: ALLGENERATEDROUTES[1],
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:38:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// Workaround to prevent msw/data to create activeMissionIds although setting them explicitly as undefined
// const [_, ...vehiclesWithoutMission] = VINS

MockDatabase.vehicle.updateMany({
  where: {
    vin: {
      equals: VINS[3]
    }
  },
  data: {
    activeMissionId: undefined
  }
})

MockDatabase.roadSegmentStatus.create({
  segment: MockDatabase.roadSegment.create({
    from: 12727,
    to: 12728,
    direction: RoadSegmentDirection.MAIN,
    waypoints: ROAD_RESTRICTION_WAYPOINTS[0]
  })
})

MockDatabase.roadSegmentStatus.create({
  segment: MockDatabase.roadSegment.create({
    from: 12730,
    to: 12731,
    direction: RoadSegmentDirection.MAIN,
    waypoints: ROAD_RESTRICTION_WAYPOINTS[1]
  })
})

MockDatabase.exceptionResponse.create({
  exceptionSegment: MockDatabase.exceptionSegment.create()
})
MockDatabase.exceptionResponse.create({
  exceptionSegment: MockDatabase.exceptionSegment.create()
})
MockDatabase.exceptionResponse.create({
  exceptionSegment: MockDatabase.exceptionSegment.create()
})
