import { decode } from '@here/flexpolyline'
import { Position } from '@rio-cloud/rio-uikit/mapTypes'
import GenerateRouteForm from 'features/simulations/GenerateRouteForm'
import SimulationMap from 'features/simulations/SimulationMap'
import StopSimulationForm from 'features/simulations/StopSimulationForm'
import UploadScenarioForm from 'features/simulations/UploadScenarioForm'
import { useEffect, useState } from 'react'

const domain = import.meta.env.VITE_SIMULATION_ROUTE
const here_api_key = import.meta.env.VITE_MAP_SERVICE_API_KEY

const SimulationsPage = () => {
  const initialPosition = { lat: 0, lng: 0 }
  const [startPoint, setStartPoint] = useState<Position | undefined>(initialPosition)
  const [intermediatePoint, setIntermediatePoint] = useState<Position | undefined>(initialPosition)
  const [destinationPoint, setDestinationPoint] = useState<Position | undefined>(initialPosition)
  const [route, setRoute] = useState<Position[] | undefined>(undefined)
  const isIntermediateValid = intermediatePoint?.lat !== 0 && intermediatePoint?.lng !== 0

  const resetIntermediatePoint = () => {
    setIntermediatePoint(initialPosition)
  }

  const clearRoute = () => {
    setStartPoint(initialPosition)
    setIntermediatePoint(initialPosition)
    setDestinationPoint(initialPosition)
    setRoute(undefined)
  }

  const getRoute = async () => {
    const pathQueryString = `origin=${startPoint?.lat},${startPoint?.lng}&${isIntermediateValid ? `&via=${intermediatePoint?.lat},${intermediatePoint?.lng}!passThrough=true` : ''}&destination=${destinationPoint?.lat},${destinationPoint?.lng}`
    const url = `https://router.hereapi.com/v8/routes?transportMode=truck&${pathQueryString}&return=polyline,summary,turnByTurnActions&apiKey=${here_api_key}`
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: '{}',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await res.json()
      const polyline = data.routes[0].sections[0].polyline
      const decodedPolyline = decode(polyline)
      const decodedRoute = decodedPolyline.polyline.map((_: number[]): Position => {
        if (!_[0] || !_[1]) {
          throw Error('A coordinate could not be found')
        }
        return { lat: _[0], lng: _[1] }
      })
      setRoute(decodedRoute)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      startPoint?.lat !== 0 &&
      startPoint?.lng !== 0 &&
      destinationPoint?.lat !== 0 &&
      destinationPoint?.lng !== 0
    ) {
      getRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPoint, destinationPoint, intermediatePoint])

  return (
    <div className="relative size-full">
      <SimulationMap
        route={route}
        hasIntermediate={isIntermediateValid}
        setStartPosition={setStartPoint}
        setDestinationPosition={setDestinationPoint}
        handleIntermediatePoint={setIntermediatePoint}
      />
      <div className="absolute left-0 top-20 grid w-[360px] grid-cols-1 gap-20 px-20">
        <StopSimulationForm domain={domain} />
        <UploadScenarioForm domain={domain} />
      </div>
      <div className="absolute bottom-20 left-0 grid w-full grid-cols-6 px-20">
        <div className="col-span-5">
          <GenerateRouteForm
            route={route}
            clearRoute={clearRoute}
            startPoint={startPoint}
            setStartPoint={setStartPoint}
            intermediatePoint={intermediatePoint}
            setIntermediatePoint={setIntermediatePoint}
            destinationPoint={destinationPoint}
            setDestinationPoint={setDestinationPoint}
            resetIntermediatePoint={resetIntermediatePoint}
            hasIntermediatePoint={isIntermediateValid}
          />
        </div>
      </div>
    </div>
  )
}

export default SimulationsPage
