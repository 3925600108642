import { Severity, Vehicle } from 'API'
import { DeviationWithVIN } from 'helper/types'
import { pipe, sortBy, prop, mapValues } from 'remeda'
import { GenericRecord } from 'services/utils'

export const prioritizeDeviations = (vehicles?: GenericRecord<Vehicle>) => {
  if (!vehicles) {
    return
  }
  return pipe(
    Object.values(vehicles),
    (_) =>
      _.reduce(
        (pre: Record<string, DeviationWithVIN[]>, cur) => ({
          ...pre,
          [cur.vin]: cur.deviations
            ? cur.deviations.map((deviation) => ({ ...deviation, vin: cur.vin }))
            : []
        }),
        {}
      ),
    mapValues(sortBy([prop('severity'), 'asc'], [prop('sentAt'), 'asc'])),
    Object.values,
    (sortedDeviations) => sortedDeviations.map((_) => _[0]),
    (_: DeviationWithVIN[]) => ({
      errors: sortBy(
        _.filter((deviation) => deviation?.severity === Severity.ERROR),
        [prop('sentAt'), 'asc']
      ),
      warnings: sortBy(
        _.filter((deviation) => deviation?.severity === Severity.WARNING),
        [prop('sentAt'), 'asc']
      )
    })
  )
}

export const getLastCharacters = (value: string, numberOfCharacters: number) =>
  value.slice(-numberOfCharacters)
