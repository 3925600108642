import { createApi } from '@reduxjs/toolkit/query/react'
import { client } from 'graphql/client'
import { listExceptions, listRoadSegmentStatus } from 'graphql/queries'
import {
  ExceptionResponse,
  OnUpdateRoadSegmentStatusSubscriptionVariables,
  RequestExceptionMutationVariables,
  RoadSegmentStatus
} from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { onUpdateRoadSegmentStatus } from '../graphql/subscriptions'
import { ExceptionResponseSchema, RoadSegmentStatusSchema } from 'APIzod'
import { errorHandler } from './errors'
import { GenericRecord, denormalizeListWithId } from './utils'
import { z } from 'zod'
import { requestException } from '../graphql/mutations'

const listRoadSegmentStatusFn = async () => {
  try {
    const { data } = await client.graphql({ query: listRoadSegmentStatus })
    return {
      data: denormalizeListWithId(data.listRoadSegmentStatus)
    }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while fetching the road segment status', error)
  }
}

const listExceptionsQueryFn = async () => {
  try {
    const { data } = await client.graphql({ query: listExceptions })
    const exceptionsList = z.array(ExceptionResponseSchema()).parse(data.listExceptions)
    return {
      data: exceptionsList
    }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while fetching Exceptions list', error)
  }
}

const requestExceptionFn = async (variables: RequestExceptionMutationVariables) => {
  try {
    const { data } = await client.graphql({ query: requestException, variables })
    return {
      data: data.requestException
    }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while requesting ODD exception', error)
  }
}

export const roadRestrictionsApi = createApi({
  reducerPath: 'roadRestrictions',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    listRoadSegmentStatus: builder.query<GenericRecord<RoadSegmentStatus>, string>({
      queryFn: listRoadSegmentStatusFn
    }),
    requestException: builder.mutation<ExceptionResponse, RequestExceptionMutationVariables>({
      queryFn: requestExceptionFn
    }),
    listExceptions: builder.query<ExceptionResponse[], string>({
      queryFn: listExceptionsQueryFn
    }),
    onUpdateRoadSegmentStatusSubscription: builder.query<
      GenericRecord<RoadSegmentStatus>,
      OnUpdateRoadSegmentStatusSubscriptionVariables
    >({
      queryFn: listRoadSegmentStatusFn,
      onCacheEntryAdded: async (_, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) => {
        const updateCache = (data: RoadSegmentStatus) => {
          const roadSegmentStatus = RoadSegmentStatusSchema().parse(data)
          updateCachedData((draft) => {
            const key = `${roadSegmentStatus.segment.from}:${roadSegmentStatus.segment.to}`
            draft[key] = roadSegmentStatus
          })
        }

        await cacheDataLoaded

        const connection = client.graphql({ query: onUpdateRoadSegmentStatus }).subscribe({
          next: ({ data }) => {
            if (!data.onUpdateRoadSegmentStatus) {
              console.error(
                '[onUpdateRoadSegmentStatusSubscription]: Please make sure all the attributes are returned in the mutation'
              )
            }
            updateCache(data.onUpdateRoadSegmentStatus)
          },
          error: (error) => console.warn(error)
        })
        cacheEntryRemoved
          .then(() => {
            connection.unsubscribe()
          })
      }
    })
  })
})

export const {
  useLazyListRoadSegmentStatusQuery,
  useListRoadSegmentStatusQuery,
  useOnUpdateRoadSegmentStatusSubscriptionQuery,
  useListExceptionsQuery,
  useRequestExceptionMutation
} = roadRestrictionsApi
