import { JWT } from 'aws-amplify/auth';

export const setHeaders = (token?: JWT) => {
  if(token) {
    return {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    }
  }
}
